import React from 'react';
import Layout from '../layouts/index';
import Hero from '../components/hero/Hero';
import HeroIllustration from '../components/hero/HeroIllustration';

export default function IndexPage() {
	return (
		<Layout>
			<Hero
				title="I wish there was an app that..."
				content="Hi - welcome to 24dB. We are developing a portfolio of niche products that solve everyday problems for businesses and individuals. Got an idea for an app that solves a problem? Let us know and maybe we can develop it for you!"
				illustration={HeroIllustration}
			/>
		</Layout>
	);
}
